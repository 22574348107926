import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';

interface MyVideoProps extends RouteComponentProps<{name: string}> {

}

const MyVideo: React.FC<MyVideoProps> = (props) => {
    return (
      <div>
        <Link to="/">Go Back</Link>
        <ReactPlayer
            url='https://www.youtube.com/watch?v=6u3q2g6lbs8'
            controls={true}
            playing={true}
        />
      </div>
    )
}

export default MyVideo