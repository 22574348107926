import React from "react";
import Slider from "react-slick";
import { slideSlick } from "../../slider/script";
import CallAction from "../../elements/callaction/CallAction";
import { FaMapSigns, FaParking, FaTools, FaDoorOpen, FaTv, FaHands, FaUtensils } from 'react-icons/fa'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faInfo, faMountain, faPaintBrush, faVirus } from '@fortawesome/free-solid-svg-icons';
import ServiceTile, { ServiceTileProps } from "./components/ServiceTile";


const SlideList = [
    {
        textPosition: 'text-right',
        bgImage: 'bg_image--33',
        category: '',
        title: 'Selah Retreat',
        description: 'Your digital guide for your stay',
    }
]

const ServiceList: ServiceTileProps[] = [
    {
        icon: <FaDoorOpen />,
        title: 'Welcome from the Owners',
        description: 'Hear from the Thompsons.',
        href: '/view/owner'
    },
    {
        icon: <FaTools />,
        title: 'Current Cabin Operations',
        description: 'Stay up to date on how things are working.',
        href: '/view/operations'
    },
    {
        icon: <FaParking />,
        title: 'Parking',
        description: 'Let us help you find a place to park.',
        href: '/view/parking',
    },
    {
        icon: <FaMapSigns />,
        title: 'Guest Directory',
        description: 'Digital replacement to your in room compendium.',
        href: '/view/guest-directory',
    },
    {
        icon: <FaTv />,
        title: 'Cabin Technology',
        description: `Learn more about your cabin's technology`,
        href: '/view/technology'
    },

    {
        icon: <FaUtensils />,
        title: 'Food and Beverage',
        description: 'Find something to eat',
        href: '/view/food-and-beverage'
    },
    {
        icon: <FontAwesomeIcon icon={faInfo}/>,
        title: 'Site Info',
        description: 'General information about the retreat.',
        href: '/view/art',
    },
    {
        icon: <FontAwesomeIcon icon={faVirus}/>,
        title: 'WA State COVID Updates',
        description: 'Learn about the current state of COVID in Washington.',
        href: 'https://coronavirus.wa.gov/',
        targetBlank: true
    },
    {
        icon: <FontAwesomeIcon icon={faMountain}/>,
        title: 'Explore the Area',
        description: 'Learn about the area.',
        href: '/view/explore',
    },
]


const Home: React.FC = () => {

    return(
        <> 
            {/* Start Slider Area   */}
            <div className="slider-wrapper">
                <div className="slider-activation">
                    <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                        {SlideList.map((value , index) => (
                            <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition} slider-text`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title">Selah Retreat</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            {/* End Slider Area   */}
            
            {/* Start Service Area */}
            <div className="service-area ptb--30 bg_color--1">
                <div className="container">
                    <div className="row service-one-wrapper">
                        {ServiceList.map( (val , i) => (
                            <ServiceTile key={i} {...val}/>
                        ))}
                    </div>
                </div>
            </div>
            {/* End Service Area */}
            {/* Start call To Action  */}
            <CallAction />
            {/* End call To Action  */}

        </>
    )
}
export default Home;