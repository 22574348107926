import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import Viewer, {Worker, SpecialZoomLevel, ToolbarSlot, Slot, defaultLayout, RenderToolbar, PageChangeEvent} from '@phuocng/react-pdf-viewer'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { useMediaQuery } from 'react-responsive';
import Button from 'react-bootstrap/esm/Button';


interface PdfViewerProps extends RouteComponentProps<{name: string}> {

}

const renderToolbar = (toolbarSlot: ToolbarSlot): React.ReactElement => {
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    justifyContent: 'center',
                }}
            >
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.previousPageButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.nextPageButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomOutButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomPopover}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomInButton}
                </div>
            </div>
        </div>
    );
};

const layout = (
  isSidebarOpened: boolean,
  container: Slot,
  main: Slot,
  toolbar: RenderToolbar,
  sidebar: Slot,
): React.ReactElement => {
  return defaultLayout(
      isSidebarOpened,
      container,
      main,
      toolbar(renderToolbar),
      sidebar,
  );
};

const MyDocument: React.FC<PdfViewerProps> = (props) => {
    const file = props.match.params.name;
    const isMobile = useMediaQuery({query: '(max-device-width: 425px)'})
    const defaultScale = isMobile ? "PageWidth" as SpecialZoomLevel : 1.70
    const pdfUri = () => {
      switch(file){
        case "guest-directory":
            if (isMobile){
                return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/SELAH/Selah%20Retreat%20Guest%20Directory.docx.pdf";
            } else {
                return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/SELAH/Selah%20Retreat%20Guest%20Directory.docx.pdf";
            }
        case "owner":
            return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/SELAH/Test%20PDF.pdf";
        case "operations":
            return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/SELAH/Current%20Operations.pdf";
        case "parking":
            return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/SELAH/Parking.pdf";
        case "technology":
            return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/SELAH/Cabin%20Technology.pdf";
        case "food-and-beverage":
            return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/SELAH/Food%20and%20Beverage.pdf";
        case "art":
            return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/SELAH/Site%20Info.pdf";
        case "explore":
            return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/SELAH/Explore%20the%20Area.pdf";
        default:
          return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/SELAH/Selah%20Retreat%20Guest%20Directory.docx.pdf";
      }
    }

    const handlePageChange = (e: PageChangeEvent) => {
    }

    return (
      <div style={{marginTop: '10px'}}>
        <Link to="/" style={{margin: '20px'}}>
            <Button variant={"secondary"}>
                Go Back
            </Button>
        </Link>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div style={{ height: '900px', margin: '20px' }}>
                <Viewer
                  fileUrl={pdfUri()}
                  defaultScale={defaultScale}
                  onPageChange={handlePageChange}
                  layout={layout}
                />
            </div>
        </Worker>
      </div>
    )
}

export default MyDocument