import React from 'react';

import { Theme, makeStyles, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import { isSafari } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: '50px'
    },
    gmImage: {
        height: 'auto',
        width: 'auto',
        maxWidth: '250px',
        [theme.breakpoints.down("md")]: {
            maxWidth: '150px',
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: '20px'
        }
    },
    signature: {

    },
    messageBody: {
        marginRight: '10%',
        marginLeft: '10%'
    },
    mobileBody: {
        [theme.breakpoints.up("md")]: {
            display: 'none'
        }
    },
    largeBody: {
        [theme.breakpoints.down("md")]: {
            display: 'none'
        }

    },
    header: {
        fontSize: '2.0243rem',
        fontFamily: 'Avendia Std Regular,Din Regular,Arial',
        fontWeight: 400,
        lineHeight: 1.235
    }
  })
);

export const GMMessage: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container>
                <Link to="/" style={{margin: '20px'}}>
                    <Button variant={"secondary"}>
                        Go Back
                    </Button>
                </Link>
            </Grid>
            <div className={classes.messageBody}>
                <Grid container alignItems='center' justify='center'>
                    <Grid item sm={3}>
                        <Grid container alignContent='center' alignItems='center' justify='center'>
                            <Grid item>
                                <img src="/assets/images/gmmessage/IMG_1090.jpg" className={classes.gmImage}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={9}>
                        <Grid container>
                            <Grid item>
                                {
                                    !isSafari
                                    ? <div className={classes.header}>To Our Valued Guests</div>
                                    : <div></div>
                                }
                            </Grid>
                        </Grid>
                        <br/>
                        <Typography >
                            On behalf of the Embassy Suites Seattle Family, Welcome to the Embassy Suites Seattle Downtown 
                            Pioneer Square and thank you for choosing us. We relish this opportunity to serve you, shine the light
                            and extend the warmth of hospitality as Hilton originally imagined it. 
                        </Typography>
                        <br/>
                        <Typography className={classes.largeBody}>
                            While this world looks and feels different than the one we were once accustom, I am encouraged 
                            by this teams resilience, creativity and unwavering customer care in the face of constant change 
                            day in and out. Hilton recently developed the “CleanStay” Program to reinforce our commitment 
                            to the wellbeing of guests and Team Members. This industry leading initiative impacts every aspect 
                            of your hotel experience. In addition, please utilize the various contactless features throughout your 
                            room from this e-Directory; Alexa, your in-room virtual liaison to control your guest room TV, Heating 
                            and Cooling thermostat, provide music, the occasional dad joke, and hotel FAQs; and Milton, our 
                            Hotels onsite Team Assistant robot for amenity delivery. 
                        </Typography>

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <Typography className={classes.mobileBody}>
                            While this world looks and feels different than the one we were once accustom, I am encouraged 
                            by this teams resilience, creativity and unwavering customer care in the face of constant change 
                            day in and out. Hilton recently developed the “CleanStay” Program to reinforce our commitment 
                            to the wellbeing of guests and Team Members. This industry leading initiative impacts every aspect 
                            of your hotel experience. In addition, please utilize the various contactless features throughout your 
                            room from this e-Directory; Alexa, your in-room virtual liaison to control your guest room TV, Heating 
                            and Cooling thermostat, provide music, the occasional dad joke, and hotel FAQs; and Milton, our 
                            Hotels onsite Team Assistant robot for amenity delivery. 
                        </Typography>

                        <br/>
                        <Typography>
                            Finally, I cannot properly welcome any guest to the hotel without recognizing that this hotel is in the 
                            Pioneer Square neighborhood, the heart of the historic Seattle Downtown, surrounded by the retail 
                            core to the North, Chinatown, the International District to the East, SODO district to the South home 
                            to CenturyLink Field, T-Mobile Park and Central Waterfront to the West; All of which rest on ancestral 
                            lands of the Coast Salish people. We recognize, honor and respect the indigenous people of this land,
                            their practices; past, present and future. We recognize, honor, and respect this land. After all, we are 
                            all guests on this planet.
                        </Typography>
                        <br/>
                        <Typography>
                            I truly hope you have a memorable experience. Please reach out to the front desk, duty manager or 
                            Myself if there is anything we may do to make your stay more pleasurable. 
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <br/>
                        <Typography>
                            Yours in Service,
                        </Typography>
                        <img src="/assets/images/gmmessage/KuhnsSig.png"/>
                        <Typography>
                            Nicholas Kuhns
                        </Typography>
                    </Grid>
                </Grid>

            </div>
        </div>
    )
}