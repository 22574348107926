import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import FiberManualRecord  from '@material-ui/icons/FiberManualRecord'
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import ReactPlayer from 'react-player/lazy';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        background: 'url(/assets/images/bg/background.jpg) no-repeat center center fixed',
        backgroundSize: 'cover'
    },
    category: {
        minWidth: '50%'
    },
    fullCategory: {
        minWidth: '100%'
    },
    info: {
        marginTop: '15px'
    },
    listItemText: {
        marginTop: '12px',
        fontSize: '50px'
    },
    notice: {
        justifyContent: "center",
        paddingTop: '50px',
        marginLeft: '40px',
        marginRight: '40px'
    },
    categories: {
        justifyContent: "center",
        margin: '20px 25% 20px 25%'
    },
    categoriesMobile: {
        margin: '20px 5% 20px 5%'
    },
    video: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '10px'
    },
    subText: {
        overflowY: 'scroll',
        fontStyle: 'italic'
    },
    listItem: {
        marginTop: '5px'
    }
  }));

interface ISize {
    height?: string,
    width?: string
}

export const Alexa: React.FC = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery({query: '(max-device-width: 425px)'})
    const isTablet = useMediaQuery({query: '(max-device-width: 768px)'})

    const getVideoSize = () => {
        const size: ISize = {
            height: undefined,
            width: undefined
        }
        if (isMobile) {
            size.height = '100%'
            size.width = '100%'
        } else if (isTablet) {
            size.height = '250px'
            size.width = 'auto'
        } else {
        }
        return size
    }

    return (
        <div id={"root-alexa"} className={classes.root}>
            <Grid container>
                <Link to="/technology" style={{margin: '20px'}}>
                    <Button variant={"secondary"}>
                        Go Back
                    </Button>
                </Link>
            </Grid>
            <div style={{margin: '30px'}}>
                <Typography component={'span'} variant="h2">
                    Alexa
                </Typography>
                <div>
                    <Typography component={'span'} variant='body1' >
                        Alexa is your voice-activated virtual concierge. To use Alexa, just say the wake word, “Alexa,” and she will light up to let you know she is listening.  To get started, just ask, “Alexa, what can you do here?”
                    </Typography>
                </div>
            </div>
            <div className={clsx({[classes.categories]: true, [classes.categoriesMobile]: isMobile})}>
            <Grid container spacing={3}>
                <Grid item className={classes.category}>
                    <Typography component={'span'} variant={'h4'}>
                        Music and News
                    </Typography>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, play music"
                        </Typography>
                        <br />
                        <Typography component={'span'} className={classes.subText}>
                            also try by: artist, genre, radio
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, play the news"
                        </Typography>
                        <br />
                        <Typography component={'span'} className={classes.subText}>
                            also try by: BBC, Freakonomics
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.category}>
                    <Typography component={'span'} variant={'h4'}>
                        Device Settings
                    </Typography>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, volume [up/down]"
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, pair my phone"
                        </Typography>
                        <br />
                        <Typography component={'span'} className={classes.subText}>
                            connect to Alexa via Bluetooth
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, disconnect my device"
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item className={classes.category}>
                    <Typography component={'span'} variant={'h4'}>
                        Smart Home
                    </Typography>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, turn the thermostat [up/down]"
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, turn the fan [on/off]"
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, set the temperature to [70 degrees]"
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.category}>
                    <Typography component={'span'} variant={'h4'}>
                        Hotel Calling
                    </Typography>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, call the front desk."
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, call 13 Coins."
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, call the valet."
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, hang up."
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item className={classes.category}>
                    <Typography component={'span'} variant={'h4'}>
                        Checkout
                    </Typography>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, checkout"
                        </Typography>
                        <br />
                        <Typography component={'span'} className={classes.subText}>
                            Alexa will notify the front desk that you have checked out
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.category}>
                    <Typography component={'span'} variant={'h4'}>
                        Fun Interactions
                    </Typography>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, tell me a joke."
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, can you sing?"
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, how was your day?"
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item className={classes.fullCategory}>
                    <Typography component={'span'} variant={'h4'}>
                        TV Control
                    </Typography>
                    <div>
                        <ReactPlayer
                            url='https://youtu.be/cydUi5P7PiQ'
                            controls={true}
                            playing={false}
                            height={getVideoSize().height}
                            width={getVideoSize().width}
                            className={classes.video}
                        />
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to turn the TV [on/off]”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to turn the volume [up/down].”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to [mute/unmute] the TV.”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to set the volume to [0-70]."
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to turn the channel to [channel 5]"
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to turn the channel to [NBC]"
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to go to the TV guide”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to go [up/down/left/right]”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to choose select”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to go to the main menu”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, ask the hotel to go to the last channel”
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item className={classes.fullCategory}>
                    <Typography component={'span'} variant={'h4'}>
                        Connect Your Account
                    </Typography>
                    <br />
                    <Typography component={'span'} className={classes.subText}>
                            Log into your Amazon account to play your own music
                    </Typography>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, connect my account"
                        </Typography>
                    </div>
                    <br />
                    <Typography component={'span'} className={classes.subText}>
                        Once connected, try:
                    </Typography>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, play my [songs/playlists]."
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            "Alexa, disconnect my account."
                        </Typography>
                        <br />
                        <Typography component={'span'} className={classes.subText}>
                            Disconnect your account manually or it will automatically be disconnected at the end of your stay.
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item className={classes.fullCategory}>
                    <Typography component={'span'} variant={'h4'}>
                        Information
                    </Typography>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, set an alarm for [6 am].”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, what is the score of the Seahawks game?” 
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, what is the weather today?”
                        </Typography>
                    </div>
                    <div>
                        <Typography component={'span'} variant="h6">
                            “Alexa, where is the ballroom?”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, where is the pool?”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, what are the hours of the gym?” 
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, where is the Perch?”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, when is the evening reception?” 
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, what are the hours of Zephyr bar?”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, how do I connect to the Wi-Fi?” 
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item className={classes.fullCategory}>
                    <Typography component={'span'} variant={'h4'}>
                        Need Something?
                    </Typography>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, the TV is broken.”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, I would like my room cleaned.” 
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, bring me more shampoo.” 
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, I forgot my toothbrush.” 
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            “Alexa, I need a [towel].”
                        </Typography>
                    </div>
                    <div className={classes.listItem}>
                        <Typography component={'span'} variant="h6">
                            Alexa can notify housekeeping or maintenance staff of many different items you want, like [towel], such as: 
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <FiberManualRecord />
                                </ListItemIcon>
                                <ListItemText className={classes.listItemText}>
                                    blankets
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FiberManualRecord />
                                </ListItemIcon>
                                <ListItemText className={classes.listItemText}>
                                    soap
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FiberManualRecord />
                                </ListItemIcon>
                                <ListItemText className={classes.listItemText}>
                                    pillows
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FiberManualRecord />
                                </ListItemIcon>
                                <ListItemText className={classes.listItemText}>
                                    lotion
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FiberManualRecord />
                                </ListItemIcon>
                                <ListItemText className={classes.listItemText}>
                                    ironing board
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FiberManualRecord />
                                </ListItemIcon>
                                <ListItemText className={classes.listItemText}>
                                    many more
                                </ListItemText>
                            </ListItem>
                        </List>
                    </div>
                </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
                <Grid item className={classes.fullCategory}>
                    <Typography component={'span'} variant={'h4'}>
                        Room Service
                    </Typography>
                    <div>
                        <Typography component={'span'} variant="h6">
                            Room service is currently unavailable
                        </Typography>
                    </div>
                </Grid>
            </Grid> */}
        </div>
        <div className={classes.notice}>
            <div className={classes.info}>
                <Typography component={'span'}>
                    Notice
                </Typography>
                <div>
                    <Typography component={'span'}>
                        The Amazon Echoes are property of Embassy Suites and are programmed to only work for this room. The devices will not operate outside of your room. If a device is missing from your room upon check-out, a replacement charge of up to $500.00 will be added to your final bill. 
                    </Typography>
                </div>
            </div>
            <div className={classes.info}>
                <Typography component={'span'}>
                    Privacy
                </Typography>
                <div>
                    <Typography component={'span'}>
                        If you do not want to use Alexa, you can push the microphone on/off button on the top of the Echo device. The Echo light right will turn red and Alexa won’t be able to hear you until you push the button again. Please let the front desk know if you would like the device removed from your room.
                    </Typography>
                </div>
            </div>
            <div className={classes.info}>
                <Typography component={'span'}>
                    To learn more about Alexa, go to:
                </Typography>
                <div>
                    <Typography component={'span'}>
                        www.amazon.com/alexaforhospitality/guest
                    </Typography>
                </div>
            </div>
            <div className={classes.info}>
                <Typography component={'span'}>
                To provide feedback about your experience, scan the QR code with your phone and you’ll be directed to our survey: 
                </Typography>
                <div>
                    <img alt="survey-code" src="/survey.png" />
                </div>
            </div>
        </div>
    </div>
    )
}