import React from 'react';

import { Theme, makeStyles, Grid, Typography, ListItemIcon, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import { isSafari } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: '50px'
    },
    logo: {
        height: 'auto',
        width: 'auto',
        maxWidth: '250px',
        [theme.breakpoints.down("md")]: {
            maxWidth: '150px',
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: '20px'
        }
    },
    listItem: {
        marginTop: '5px',
        marginBottom: '25px'
    },
    secondaryList: {
        marginLeft: '10%',
    },
    messageBody: {
        marginRight: '10%',
        marginLeft: '10%',
    },
    mobileText: {
        [theme.breakpoints.down("sm")]: {
            fontSize: '10px'
        }
    },
    icon: {
        marginBottom: '10px'
    },
    header: {
        fontSize: '2.0243rem',
        fontFamily: 'Avendia Std Regular,Din Regular,Arial',
        fontWeight: 400,
        lineHeight: 1.235
    },
    bodyText: {
        // fontSize: '1.125rem',
        // fontFamily: 'Avendia Std Regular,Din Regular,Arial',
        // fontWeight: 500,
        // lineHeight: 1.7,
    }
  })
);

export const HotelOperations: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Link to="/" style={{margin: '20px'}}>
                    <Button variant={"secondary"}>
                        Go Back
                    </Button>
                </Link>
            </Grid>
            <div className={classes.messageBody}>
                <Grid container alignContent='center' alignItems='center' justify='center'>
                    <Grid item>
                        <img src="/assets/images/current-operations/ESSlogo.png" className={classes.logo}/>
                    </Grid>
                </Grid>
                <Grid container alignItems='center' justify='center'>
                    <Grid item>
                        <div>
                            {
                                !isSafari
                                ? <div className={classes.header}>Dear Valued Guests</div>
                                : <div></div>
                            }
                        </div>
                        <br/>
                        <div>
                            <Typography variant='h6' className={classes.bodyText}>
                                Welcome to the Embassy Suites Seattle Downtown Pioneer Square. 
                                As a result of the novel coronavirus (COVID-19), the hospitality 
                                industry is experiencing an impact to business that will require the following changes within the hotel operation: 
                            </Typography>
                        </div>
                        <div>
                            <List>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.icon}>
                                        <FiberManualRecordIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        className={classes.mobileText}
                                        primary={
                                            `Per King County Public Health Department, all individuals must wear face coverings over their noses and mouths when they will be at (1) indoor public settings, or (2) outdoor public locations and cannot
                                            maintain distancing of approximately six feet from another individual who does not share their
                                            household. Individuals should use fabric coverings, such as cloth face masks, scarves and bandana
                                            coverings or other material as recommended by CDC.`
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon className={classes.icon}>
                                        <FiberManualRecordIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            `Breakfast will be served with grab and go options.`
                                        }
                                    />
                                </ListItem>
                                <List className={classes.secondaryList}>
                                    <ListItem className={classes.listItem}>
                                        <ListItemIcon className={classes.icon}>
                                            <FiberManualRecordOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={
                                                `Breakfast hours are 6am to 9am daily. `
                                            }
                                        />
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <ListItemIcon className={classes.icon}>
                                            <FiberManualRecordOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={
                                                `Seating is not currently available per King County mandates.`
                                            }
                                        />
                                    </ListItem>
                                </List>
 
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.icon}>
                                        <FiberManualRecordIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            `The Hotel Pool and Fitness Center will be closed as mandated in King County. `
                                        }
                                    />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon className={classes.icon}>
                                        <FiberManualRecordIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            `13 Coins is open for takeout orders Monday through Wednesday from 1 0am to 8pm; Thursday through Sunday from 9am to 9pm.`
                                        }
                                    />
                                </ListItem>
                                <List className={classes.secondaryList}>
                                    {/* <ListItem className={classes.listItem}>
                                        <ListItemIcon className={classes.icon}>
                                            <FiberManualRecordOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={
                                                `Take-Out is also available during these hours.`
                                            }
                                        />
                                    </ListItem> */}
                                    <ListItem className={classes.listItem}>
                                        <ListItemIcon className={classes.icon}>
                                            <FiberManualRecordOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={
                                                `For off hour deliveries, we recommend the following food delivery services: Uber Eats, Postmates, Door Dash, Grub Hub and Caviar.`
                                            }
                                        />
                                    </ListItem>
                                </List>
                                <ListItem className={classes.listItem} style={{paddingTop: '5px'}}>
                                    <ListItemIcon className={classes.icon}>
                                        <FiberManualRecordIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            `In to order comply with King County mandates and promote social distancing; the nightly Evening Reception will not be held at this time.`
                                        }
                                        
                                    />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.icon}>
                                        <FiberManualRecordIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            `Self Service laundry is available on the 8th floor. Please contact the Front Desk for assistance.`
                                        }
                                    />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.icon}>
                                        <FiberManualRecordIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            `Valet laundry is on limited service, please contact the Front Desk for the most up to date information. `
                                        }
                                    />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.icon}>
                                        <FiberManualRecordIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            `Valet parking is currently not available.`
                                        }
                                    />
                                </ListItem>
                            </List>
                        </div>
                        <br/>
                        <Typography variant="h6">
                            We truly apologize for any inconvenience this may cause.
                            If we can do anything to make your stay more comfortable certainly reach out to the Front Desk. 
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <br/>
                        <Typography variant="h6">
                            Best Regards,
                        </Typography>
                        <br/>
                        <img src="/assets/images/gmmessage/KuhnsSig.png"/>
                        <Typography variant="h6">
                            Nicholas Kuhns
                        </Typography>
                        <Typography variant="h6">
                            General Manager
                        </Typography>
                    </Grid>
                </Grid>

            </div>
        </div>
    )
}